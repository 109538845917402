<template>
   <div>
      <!-- <v-layout class="emb-card mb-6 mt-4"> -->
			<app-card 
				customClasses="mb-6 mt-6"
            colClasses="xl12 lg12 md12 xs12 sm12"
            :removePanel="true"
            :viewMore="true"
            :disableNotification="true"
            :settings="true"
            >
            <buy-or-sell></buy-or-sell>
         </app-card>
      <!-- </v-layout> -->
   </div>
</template>

<script>
   import BuyOrSell from "Components/Widgets/BuyOrSell"; 
   
   export default {
      components: {
         BuyOrSell
      }
   }
</script>